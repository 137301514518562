import React, { useState, useRef, useEffect } from 'react';
import { Billboard, PivotControls } from '@react-three/drei';
import { Vector3, Quaternion, Euler } from 'three';
import { TextureLoader } from 'three';

const CameraView = ({ id, position, rotation, updatePositionRotationCamera }) => {

  const [currentPosition, setCurrentPosition] = useState(position);
  const [currentRotation, setCurrentRotation] = useState(rotation);
  const [texture, setTexture] = useState(null);

  const cameraRef = useRef();

  useEffect(() => {
    const loader = new TextureLoader();
    loader.load(
      `${process.env.PUBLIC_URL}/camera.png`,
      (loadedTexture) => setTexture(loadedTexture),
      undefined,
      (error) => console.error("Error loading texture:", error)
    );
  }, []);

  useEffect(() => {
    setCurrentPosition(position);
    setCurrentRotation(rotation);
  }, [position, rotation]);
  
  const handleCameraDrag = (l, dl, w, dw) => {
    const newPosition = new Vector3();
    const rotation = new Quaternion();
    w.decompose(newPosition, rotation, new Vector3());

    setCurrentPosition(newPosition.toArray());

    const eulerRotation = new Euler().setFromQuaternion(rotation);
    setCurrentRotation([eulerRotation.x, eulerRotation.y, eulerRotation.z]);
  };

  const handleCameraDragEnd = () => {
    if (cameraRef.current) {
      const finalPosition = cameraRef.current.position;
      const finalQuaternion = cameraRef.current.quaternion;
      const finalEuler = new Euler().setFromQuaternion(finalQuaternion);
    
      updatePositionRotationCamera(id, finalPosition.toArray(), finalEuler.toArray());
    }
  };
  

  return (
    <group
      ref={cameraRef}
      position={currentPosition} 
      rotation={currentRotation} 
    >
      <PivotControls anchor={[0, 0, 0]} scale={0.5} lineWidth={3.5}
              onDrag={handleCameraDrag}
              onDragEnd={handleCameraDragEnd}>
        <Billboard>
          <mesh
            key={id}
          >
            <planeGeometry args={[1, 1]} />
            <meshBasicMaterial attach="material" map={texture} transparent={true} />
          </mesh>
        </Billboard>
      </PivotControls>
    </group>
  );
};

export default CameraView;
