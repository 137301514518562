import React, { useState, useEffect } from 'react';
import '../stylesheets/AssetLoader.css';

const AssetLoader = ({ fileInputFolderRef, fileInputZipRef, handleModelUpload, loading, toggleAssetLoader }) => {
  
  const uploadFileFromLocalFolderButton = () => {
    fileInputFolderRef.current.click();
  };

    
  const uploadFileFromLocalZipButton = () => {
    fileInputZipRef.current.click();
  };

  return (
    <div className='object-assetloader-container'>
      <h3>Asset Loader</h3>
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px'}} />
      <div className='field-assetloader-container'>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className='assetloader-button' 
            onClick={uploadFileFromLocalFolderButton}
            >Upload Asset from device folder</button>
          </div>
          <div className='field'>
            <input
              ref={fileInputFolderRef}
              type="file"
              multiple
              webkitdirectory="true"
              directory="true"
              onChange={handleModelUpload}
              style={{ display: 'none' }}
              disabled={loading}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className='assetloader-button' 
            onClick={uploadFileFromLocalZipButton}
            >Upload Asset from device ZIP</button>
          </div>
          <div className='field'>
            <input
              ref={fileInputZipRef}
              type="file"
              accept=".zip"
              onChange={handleModelUpload}
              style={{ display: 'none' }}
              disabled={loading}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className='assetloader-button' 
            >Import Asset from XRECO NMR</button>
          </div>
          <button type="button" className='close-button' onClick={toggleAssetLoader}>Close</button>
        </form>
      </div>
    </div>
  );
};

export default AssetLoader;
