import React, { useState, useEffect } from 'react';
import '../stylesheets/AudioLoader.css';

const AudioLoader = ({ fileAudioRef, handleAudioUpload, loading, toggleAudioLoader }) => {
  
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [isUrlValid, setIsUrlValid] = useState(false);

  const uploadAudioFromFileButton = () => {
    fileAudioRef.current.click();
  };

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setIsUrlValid(validateUrl(url));
  }, [url]);

  return (
    <div className='object-Audioloader-container'>
      <h3>Audio Loader</h3>
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px'}} />
      <div className='field-Audioloader-container'>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className='Audioloader-button' 
            onClick={uploadAudioFromFileButton}
            >Upload Audio from your device</button>
          </div>
          <div className='field'>
            <input
              id="AudioInput"
              ref={fileAudioRef}
              type="file"
              onChange={handleAudioUpload}
              style={{ display: 'none' }}
              disabled={loading}
              accept=".mp3,audio/mpeg"
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input 
            className='urlLoader-input' 
            type="text" 
            name="urlAudioloader" 
            placeholder="Enter Audio URL" 
            value={url} 
            onChange={(e) => setUrl(e.target.value)} 
            disabled={loading} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className={`Audioloader-button ${!isUrlValid || loading ? 'disabled' : ''}`}
            onClick={() => handleAudioUpload(null, url)}
            disabled={!isUrlValid || loading} 
            >Import Audio from URL</button>
          </div>
          <button type="button" className='close-button' onClick={toggleAudioLoader}>Close</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default AudioLoader;
