import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../stylesheets/AssetData.css';
import { debounce } from 'lodash';

const AssetData = React.memo (({ hideModel, hiddenModels, modelFiles, setSelectedModel}) => { 

  const handleSelectModel= useCallback(
    debounce((index) => {
      const selectedAsset = modelFiles[index];
      if (selectedAsset) {
        setSelectedModel({
          index,
          currentPosition: selectedAsset.position,
          currentRotation: selectedAsset.rotation,
          currentScale: selectedAsset.scale,
          modelName: selectedAsset.name,
        });
      }
    }, 100),
    [modelFiles, setSelectedModel]
  );
  
  return (
    <div className="asset-data">
      <h3>Assets</h3>
      <div className="asset-list">
        {modelFiles.map((modelFile, index) => (
          <div key={index} className="asset-item" id={`asset-item-${index}`} onClick={() => handleSelectModel(index)}>
          <span className="asset-name">{modelFile.name}</span>
            <FontAwesomeIcon 
              icon={hiddenModels.includes(index) ? faEyeSlash : faEye} 
              className='iconStyleAsset' onClick={() => hideModel(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default AssetData;