import React, { useState, useEffect, useRef } from 'react';
import '../stylesheets/Project.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPlay } from '@fortawesome/free-solid-svg-icons';

function Popup({ handleClosePopup, show, handleProjectData }) {

  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const projectNameRef = useRef(null);
  const [projectName, setProjectName] = useState('');
  const [environmentData, setEnvironmentData] = useState("0");
  const [urlEnvironmentData, setUrlEnvironmentData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [jsonData, setJsonData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedURLJSONFileName, setSelectedURLJSONFileName] = useState(null);
  const [selectedURLFromTable, setSelectedURLFromTable] = useState(null);
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('tab1');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const hdr1_URL="https://xrcapsule.visyon.tech/public/old_depot_2k.hdr";
  const hdr2_URL="https://xrcapsule.visyon.tech/public/buikslotermeerplein_2k.hdr";
  const hdr3_URL="https://xrcapsule.visyon.tech/public/sunset_jhbcentral_2k.hdr";
  const hdr4_URL="https://xrcapsule.visyon.tech/public/zwartkops_curve_afternoon_2k.hdr";

  const rectanglesData = [
    { template: 'Blank', background: '/backgrounds/blank-background.jpg', unit:'meters' },
    { template: 'Smartphone AR', background: '/backgrounds/smartphone-ar-background.jpg', unit:'meters' },
    { template: 'Smartphone 3D Viewer', background: '/backgrounds/smartphone-3d-viewer-background.jpg', unit:'meters' },
    { template: 'Quest 3 AR', background: '/backgrounds/quest-3-ar-background.jpg', unit:'meters' },
    { template: 'Infographics', background: '/backgrounds/infographics-background.jpg', unit:'meters' },
    { template: 'Virtual Production', background: '/backgrounds/virtual-production-background.jpg', unit:'meters' },
  ];

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/bootstrap/bootstrap.min.css';
    link.integrity = 'sha384-pzjw8f+ua7Kw1TIq0v8Fq0b3E6KjUlA1eN2o0N2YzLvqv6X8zkh1ZdxRE3wzZZFf';
    link.crossOrigin = 'anonymous';
    
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (show) {
      projectNameRef.current.focus();
      getJsonFilesList();
    }
  }, [show]);

  useEffect(() => {
    if (activeTab === 'tab1') {
      setIsButtonDisabled(!projectName);
    } else if (activeTab === 'tab2') {
      setIsButtonDisabled(selectedRow === null);
    }
  }, [projectName, activeTab, selectedRow]);  

  useEffect(() => {
    if (activeTab === 'tab1') {
      setSelectedURLFromTable(null);
      setSelectedURLJSONFileName(null);
      setSelectedRow(null);
    }
  }, [activeTab]);

  const handleClick = () => {
    if (isButtonDisabled) {
      setErrorMessage('Please complete all required fields');
    }
  };

  const createButton = () => {
    let projectName = "Project Name";
    let selectedEnvironment = "0";
    if (activeTab === 'tab1') {
      projectName = document.getElementById('projectName').value;
      selectedEnvironment = document.getElementById('environmentSelector').value;
    }

    const selectedDevice = rectanglesData[selectedIndex];
    const device = selectedIndex;
    const units = selectedDevice.unit;
    const deviceText = selectedDevice.template;                
    const url = selectedURLFromTable;

    let JSONFileName = null;
    if(selectedURLJSONFileName != null){
      JSONFileName = selectedURLJSONFileName + '.json';
    }
    else{
      JSONFileName = deviceText + '_' + projectName + '.json';
    }

    var dimensionW = 0;
    var dimensionH = 0;
    var dimensionD = 0;

    if(parseInt(device) == 0){
      dimensionW=5;
      dimensionH=5;
      dimensionD=5;
    }
    else if(parseInt(device) == 1){
      dimensionW=5;
      dimensionH=5;
      dimensionD=2.5;
    }
    else if(parseInt(device) == 2){
      dimensionW=5;
      dimensionH=5;
      dimensionD=2.5;
    }
    else if(parseInt(device) == 3){
      dimensionW=5;
      dimensionH=5;
      dimensionD=2.5;
    }
    else if(parseInt(device) == 4){
      dimensionW=5;
      dimensionH=5;
      dimensionD=5;
    }
    else{
      dimensionW=30;
      dimensionH=30;
      dimensionD=8;
    }
    const projectData = {
      projectName,
      device,
      deviceText,
      units,
      dimensions: { w: dimensionW, h: dimensionH, d: dimensionD },
      url,
      JSONFileName,
      environmentData,
      urlEnvironmentData
    };

    handleProjectData(projectData);
    handleClosePopup();
  }

  const getJsonFilesList = async () => {
  
    try {
      const response = await fetch('https://flask-xrcapsule.visyon.tech/json_files_list');
      if (!response.ok) {
        throw new Error('Error al obtener la lista de archivos JSON');
      }
      const data = await response.json();
      setJsonData(data.files);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRowSelect = (id, url, name) => {
    setSelectedRow(id === selectedRow ? null : id);
    setSelectedURLFromTable(id === selectedRow ? null : url);
    setSelectedURLJSONFileName(id === selectedRow ? null : name);
  };

  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  const handleRectangleClick = (index) => {
    setSelectedIndex(index);
  };

  const getButtonText = () => {
    return activeTab === 'tab1' ? 'Create' : 'Open';
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
            onClick={() => setActiveTab('tab1')}
          >
            NEW PROJECT
          </button>
          <button
            className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
            onClick={() => setActiveTab('tab2')}
          >
            OPEN PROJECT
          </button>
        </div>  
        {activeTab === 'tab1' && (
          <div>
            <form className='formPopUpP'>
              {errorMessage && (
                <p className="error-message">{errorMessage}</p>
              )}
              <label className='labelPopUpP' htmlFor="projectName">Name<span style={{ color: '#ec655f' }}>*</span></label>
              <input className='inputPopUpP' type="text" id="projectName" name="projectName" 
              autoComplete="off" placeholder='Project' ref={projectNameRef} value={projectName}
              onChange={(e) => setProjectName(e.target.value)} onKeyDown={(e) => {if (e.key === 'Enter') {e.preventDefault();}}}/>              
              <label className='labelPopUpP' htmlFor="template">Template</label>
              <div className='templateRectangles-p1'>
                {rectanglesData.map((data, index) => (
                  <div 
                    key={index} 
                    className={`templateRectangles-p2 ${selectedIndex === index ? 'selected' : ''}`} 
                    onClick={() => handleRectangleClick(index)}
                    style={{
                      backgroundImage: `url(${data.background})`,
                    }}
                  >
                    <div className='templateRectangle-form'>{data.template}</div>
                    <div style={{ color: 'white', textAlign: 'left', width: '100%' }}>
                    </div>
                  </div>
                ))}
              </div>
              <label className="labelPopUpP" htmlFor="environmentSelector">
                Environment
              </label>
              <select
                className="inputPopUpP"
                id="environmentSelector"
                value={environmentData}
                onChange={(e) => {
                  const value = e.target.value;
                  setEnvironmentData(value);
                  const hdrMap = {
                    "1": hdr1_URL,
                    "2": hdr2_URL,
                    "3": hdr3_URL,
                    "4": hdr4_URL
                  };
                  setUrlEnvironmentData(hdrMap[value] || null);
                }}
              >
                <option value="0">None</option>
                <option value="1">HDRi - Old Depot</option>
                <option value="2">HDRi - Buikslotermeerplein</option>
                <option value="3">HDRi - Sunset JHB Central</option>
                <option value="4">HDRi - Zwartkops Afternoon</option>
              </select>
            </form>
          </div>
        )}
        {activeTab === 'tab2' && (
          <div>
            <form className='formPopUpP'>
              <label className='labelPopUpP' htmlFor="units" >JSON Public Files 
                <FontAwesomeIcon className='dropTableIcon' onClick={toggleTableVisibility} 
                icon={isTableVisible ? faAngleUp : faAngleDown} />
              </label>
              {isTableVisible && (
                <div className="table-container">
                  <table className="table table-striped">
                    <tbody>
                      {jsonData.map((item) => (
                        <tr
                          key={item.id}
                          className={item.id === selectedRow ? 'table-active' : ''}
                          onClick={() => handleRowSelect(item.id, item.url, item.name)}
                        >
                          <td>{item.name}</td>
                          <td>{item.url}</td>
                          <td>
                              <a href={`xrcapsule://loadcapsule?${item.url}`}>
                              <button className="deep-link-btn">
                                <FontAwesomeIcon icon={faPlay} />
                              </button>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </form>
          </div>
        )}
        <Link to={isButtonDisabled ? "#" : "/xrcapsule"}>
        <button
            className="popup-button-inP"
            onClick={() => {
              handleClick();
              if (!isButtonDisabled) {
                createButton();
              }
            }}
          >
            {getButtonText()}
          </button>
        </Link>
      </section>
    </div>
  );
}

function Project({ handleProjectData }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    togglePopup();
  }, []);

  return (
    <div className="App-project">
      <div className="gradient-backgroundP">
        <Popup
          show={showPopup}
          handleClosePopup={togglePopup}
          handleProjectData={handleProjectData}
        />
      </div>
    </div>
  );
}

export default Project;
