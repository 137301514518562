import React, { useState, useEffect } from 'react';
import '../stylesheets/ObjectData.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faVideo, faAngleDown, faAngleUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';

const ObjectData = React.memo (({ selectedModel, setSelectedModel, modelFiles, setModelFiles, modelTriggerInfo, setModelTriggerInfo, dynamicCameras, cameraClickHandler, setDynamicCameras, handleDeleteCamera }) => {
  
  const [previousSelectedIndex, setPreviousSelectedIndex] = useState(null);

  const [visibleCameraFields, setVisibleCameraFields] = useState(
    dynamicCameras.map(() => false)
  );

  const handleToggleVisibility = (index) => {
    const newVisibility = [...visibleCameraFields];
    newVisibility[index] = !newVisibility[index];
    setVisibleCameraFields(newVisibility);
  };


  useEffect(() => {
    if (selectedModel !== null && selectedModel !== undefined) {
      setPreviousSelectedIndex(selectedModel.index);
      
      if (!selectedModel.modelName) {
        selectedModel.modelName = `Asset ${selectedModel.index}`;
        setSelectedModel({ ...selectedModel });
      }

      const prevSelectedAssetItemIds = Array.from(document.querySelectorAll('.asset-item[selected]'));
      prevSelectedAssetItemIds.forEach(assetItem => {
        assetItem.removeAttribute('selected');
        assetItem.style.backgroundColor = null;
        assetItem.style.color = '#ccc';
      });

      const assetItemId = `asset-item-${selectedModel.index}`;
      const assetItemElement = document.getElementById(assetItemId);
      if (assetItemElement) {
        assetItemElement.setAttribute('selected', true);
        assetItemElement.style.backgroundColor = '#3a3e47';
        assetItemElement.style.color = '#ccc';
      }
    } else {
      if (previousSelectedIndex !== null) {
        const assetItemId2 = `asset-item-${previousSelectedIndex}`;
        const assetItemElement2 = document.getElementById(assetItemId2);
        if (assetItemElement2) {
          assetItemElement2.removeAttribute('selected');
          assetItemElement2.style.backgroundColor = null;
          assetItemElement2.style.color = '#ccc';
        }
      }
    }
  }, [selectedModel]);
  
  const updateTriggerInfo = (modelIndex, triggerInfo) => {
    setModelTriggerInfo(prevTriggerInfo => ({
      ...prevTriggerInfo,
      [modelIndex]: triggerInfo
    }));
  };

  const getTriggerInfoForModel = (modelIndex) => {
    return modelTriggerInfo[modelIndex] || [];
  };

  const updateTriggersInModelFiles = (modelIndex, newTriggers) => {
    setModelFiles(prevModelFiles => {
      const updatedModelFiles = [...prevModelFiles];
      updatedModelFiles[modelIndex] = {
        ...updatedModelFiles[modelIndex],
        triggers: newTriggers
      };
      return updatedModelFiles;
    });
  };

  const debouncedHandleTriggerButtonUpClick = debounce(() => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const triggerInfoForModel = getTriggerInfoForModel(modelIndex);
  
      if (triggerInfoForModel.length < 3) {
        let timeValue = document.getElementById('selector-time').value;
        const timeText = document.getElementById('selector-time').options[document.getElementById('selector-time').selectedIndex].text;
        const actionValue = document.getElementById('selector-action').value;
        const actionText = document.getElementById('selector-action').options[document.getElementById('selector-action').selectedIndex].text;
        
        if(parseInt(actionValue) === 2){
          timeValue = "00:00:41";
        }

        const newTriggerInfo = [...triggerInfoForModel, { time: timeValue, action: actionValue, timeText: timeText, actionText: actionText }];
        updateTriggerInfo(modelIndex, newTriggerInfo);
        updateTriggersInModelFiles(modelIndex, newTriggerInfo);
      }
    }
  }, 100); 

  const debouncedHandleTriggerButtonDownClick = debounce(() => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const newTriggerInfo = [...getTriggerInfoForModel(modelIndex)];
      newTriggerInfo.pop();
      updateTriggerInfo(modelIndex, newTriggerInfo);
      updateTriggersInModelFiles(modelIndex, newTriggerInfo);
    }
  }, 100); 

  const handleApply = () => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
      const updatedPosition = [
        parseFloat(document.getElementById('position-x').value).toFixed(3),
        parseFloat(document.getElementById('position-y').value).toFixed(3),
        parseFloat(document.getElementById('position-z').value).toFixed(3)
      ];

      const updatedRotation = [
        parseFloat(document.getElementById('rotation-x').value).toFixed(3),
        parseFloat(document.getElementById('rotation-y').value).toFixed(3),
        parseFloat(document.getElementById('rotation-z').value).toFixed(3)
      ];

      const updatedScale = [
        parseFloat(document.getElementById('scale-x').value).toFixed(3),
        parseFloat(document.getElementById('scale-y').value).toFixed(3),
        parseFloat(document.getElementById('scale-z').value).toFixed(3)
      ];
  
      setSelectedModel((prevSelectedModel) => {
        if (prevSelectedModel.index === modelIndex) {
          return {
            ...prevSelectedModel,
            currentPosition: updatedPosition, 
            currentRotation: updatedRotation,
            currentScale: updatedScale
          };
        }
        return prevSelectedModel; 
      });
  
      setModelFiles(prevModelFiles => {
        const updatedModelFiles = [...prevModelFiles];
        updatedModelFiles[modelIndex] = {
          ...updatedModelFiles[modelIndex],
          position: updatedPosition,
          rotation: updatedRotation,
          scale: updatedScale
        };
        return updatedModelFiles;
      });
    }
  };

  const handleChangeValue = (id) => {
    const updatedPosition = [
      parseFloat(document.getElementById(`camera-position-x-${id}`).value),
      parseFloat(document.getElementById(`camera-position-y-${id}`).value),
      parseFloat(document.getElementById(`camera-position-z-${id}`).value)
    ];
  
    const updatedRotation = [
      parseFloat(document.getElementById(`camera-rotation-x-${id}`).value),
      parseFloat(document.getElementById(`camera-rotation-y-${id}`).value),
      parseFloat(document.getElementById(`camera-rotation-z-${id}`).value)
    ];
  
    setDynamicCameras((prevCameras) => {
      const newCameras = prevCameras.map(camera =>
        camera.id === id 
          ? { ...camera, position: updatedPosition, rotation: updatedRotation }
          : camera
      );
      return newCameras;
    });
  };
  
  const debouncedHandleDeleteCamera = debounce(handleDeleteCamera, 100);
  const debouncedCameraClickHandler = debounce(cameraClickHandler, 100);

  const handleDeleteSelectedAsset = () => {
    if (selectedModel && selectedModel.index != null) {
      const modelIndex = selectedModel.index;
  
      setModelFiles((prevModelFiles) => {
        const updatedModelFiles = [...prevModelFiles];
        updatedModelFiles.splice(modelIndex, 1);
        return updatedModelFiles;
      });
        setSelectedModel(null);
  
      setModelTriggerInfo((prevTriggerInfo) => {
        const updatedTriggerInfo = { ...prevTriggerInfo };
        delete updatedTriggerInfo[modelIndex];
        return updatedTriggerInfo;
      });
    }
  };

  const radiansToDegrees = (radians) => radians * (180 / Math.PI);
  const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

  const [tempPosition, setTempPosition] = useState([0, 0, 0]);
  const [tempRotation, setTempRotation] = useState([0, 0, 0]);
  const [tempScale, setTempScale] = useState([1, 1, 1]);
  const [tempName, setTempName] = useState('');
  
  useEffect(() => {
    if (selectedModel) {
      if (selectedModel.currentPosition) {
        setTempPosition([
          parseFloat(selectedModel.currentPosition[0]).toFixed(3),
          parseFloat(selectedModel.currentPosition[1]).toFixed(3),
          parseFloat(selectedModel.currentPosition[2]).toFixed(3),
        ]);
      }
      if (selectedModel.currentRotation) {
        setTempRotation([
          radiansToDegrees(parseFloat(selectedModel.currentRotation[0])).toFixed(3),
          radiansToDegrees(parseFloat(selectedModel.currentRotation[1])).toFixed(3),
          radiansToDegrees(parseFloat(selectedModel.currentRotation[2])).toFixed(3),
        ]);
      }
      if (selectedModel.currentScale) {
        setTempScale([
          parseFloat(selectedModel.currentScale[0]).toFixed(3),
          parseFloat(selectedModel.currentScale[1]).toFixed(3),
          parseFloat(selectedModel.currentScale[2]).toFixed(3),
        ]);
      }
      if (selectedModel.modelName) {
        setTempName(selectedModel.modelName);
      }
    }
  }, [selectedModel?.currentPosition, selectedModel?.currentRotation, selectedModel?.currentScale, selectedModel?.modelName]);
  
  const handleChange = (e, index, type) => {
    let value = e.target.value;
    value = value.replace(',', '.');
    if (value === '-' || !isNaN(value) || value === '') {
      switch(type) {
        case 'position':
          setTempPosition((prev) => {
            const newPosition = [...prev];
            newPosition[index] = value;
            return newPosition;
          });
          break;
        case 'rotation':
          setTempRotation((prev) => {
            const newRotation = [...prev];
            newRotation[index] = value;
            return newRotation;
          });
          break;
        case 'scale':
          setTempScale((prev) => {
            const newScale = [...prev];
            newScale[index] = value;
            return newScale;
          });
          break;
        default:
          break;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedModel) {
        const modelIndex = selectedModel.index;
        const updatedPosition = [
          parseFloat(tempPosition[0]).toFixed(3),
          parseFloat(tempPosition[1]).toFixed(3),
          parseFloat(tempPosition[2]).toFixed(3)
        ];

        const updatedRotation = [
          degreesToRadians(parseFloat(tempRotation[0])).toFixed(3),
          degreesToRadians(parseFloat(tempRotation[1])).toFixed(3),
          degreesToRadians(parseFloat(tempRotation[2])).toFixed(3)
        ];

        const updatedScale = [
          parseFloat(tempScale[0]).toFixed(3),
          parseFloat(tempScale[1]).toFixed(3),
          parseFloat(tempScale[2]).toFixed(3)
        ];

        const updatedName = tempName;
    
        setSelectedModel((prevSelectedModel) => {
          if (prevSelectedModel.index === modelIndex) {
            return {
              ...prevSelectedModel,
              currentPosition: updatedPosition, 
              currentRotation: updatedRotation,
              currentScale: updatedScale, 
              modelName: updatedName
            };
          }
          return prevSelectedModel; 
        });
    
        setModelFiles(prevModelFiles => {
          const updatedModelFiles = [...prevModelFiles];
          updatedModelFiles[modelIndex] = {
            ...updatedModelFiles[modelIndex],
            position: updatedPosition,
            rotation: updatedRotation,
            scale: updatedScale,
            name: updatedName
          };
          return updatedModelFiles;
        });
      }
      e.target.blur();
    }
  };

  const handleChangeName = (e, field) => {
    const newValue = e.target.value;
    switch (field) {
      case 'name':
        setTempName(newValue);
        break;
      default:
        break;
    }
  };

  return (
    <div className='object-data-container'>
      <h3>Inspector</h3>
      {/*<hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '35px' }} />
      <h3>Cameras</h3>
      <div className="camera-list">
        {dynamicCameras.map((camera, id) => (
          <div key={camera.id} className="camera-item" id={`camera-item-${id}`}>
            <div className="camera-header">
              <p>Camera {id + 1}</p>
              <FontAwesomeIcon className='dropViwInfoCamera' onClick={() => handleToggleVisibility(id)}
                icon={visibleCameraFields[id] ? faAngleUp : faAngleDown} />
              <FontAwesomeIcon 
                icon={faTrash} 
                className='iconTrash'
                onClick={() => debouncedHandleDeleteCamera(id)}
              />
              <FontAwesomeIcon 
                icon={faVideo} 
                className='iconVideo'
                onClick={() => debouncedCameraClickHandler(camera)}
              />
            </div>
            {visibleCameraFields[id] && (
            <div className='fieldCameras'>
              <label htmlFor={`camera-position-${id}`}>P</label>
              <input
                id={`camera-position-x-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.position[0]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
              <input
                id={`camera-position-y-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.position[1]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
              <input
                id={`camera-position-z-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.position[2]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
            </div>
          )}

          {visibleCameraFields[id] && (
            <div className='fieldCameras'>
              <label htmlFor={`camera-rotation-${id}`}>R</label>
              <input
                id={`camera-rotation-x-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.rotation[0]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
              <input
                id={`camera-rotation-y-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.rotation[1]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
              <input
                id={`camera-rotation-z-${id}`}
                className='rounded-inspector-camera'
                type="number"
                value={parseFloat(camera.rotation[2]).toFixed(3)}
                onChange={() => handleChangeValue(id)}
              />
            </div>
          )}
          </div>
        ))}
      </div>*/}
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '15px' }} />
      <div>
        {selectedModel !== null && selectedModel !== undefined ? (
          <>
          <input
            type="text"
            id="modelName"
            className="rounded-inspector-name"
            value={tempName}
            onChange={(e) => handleChangeName(e, 'name')}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <div className='field-container'>
            <div className='field'>
              <label htmlFor="positionP">P</label>
              <input
                id="position-x"
                className="rounded-inspector"
                type="text"
                value={tempPosition[0]}
                onChange={(e) => handleChange(e, 0, 'position')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="position-y"
                className="rounded-inspector"
                type="text"
                value={tempPosition[1]}
                onChange={(e) => handleChange(e, 1, 'position')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="position-z"
                className="rounded-inspector"
                type="text"
                value={tempPosition[2]}
                onChange={(e) => handleChange(e, 2, 'position')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
            </div>
            <div className='field'>
            <label htmlFor="position">R</label>
              <input
                id="rotation-x"
                className="rounded-inspector"
                type="text"
                value={tempRotation[0]}
                onChange={(e) => handleChange(e, 0, 'rotation')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="rotation-y"
                className="rounded-inspector"
                type="text"
                value={tempRotation[1]}
                onChange={(e) => handleChange(e, 1, 'rotation')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="rotation-z"
                className="rounded-inspector"
                type="text"
                value={tempRotation[2]}
                onChange={(e) => handleChange(e, 2, 'rotation')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
            </div>
            <div className='field'>
              <label htmlFor="position">S</label>
              <input
                id="scale-x"
                className="rounded-inspector"
                type="text"
                value={tempScale[0]}
                onChange={(e) => handleChange(e, 0, 'scale')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="scale-y"
                className="rounded-inspector"
                type="text"
                value={tempScale[1]}
                onChange={(e) => handleChange(e, 1, 'scale')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
              <input
                id="scale-z"
                className="rounded-inspector"
                type="text"
                value={tempScale[2]}
                onChange={(e) => handleChange(e, 2, 'scale')}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength="8"
                autoComplete='off'
              />
            </div>
            <br />
          </div>
          <h3>Triggers</h3>
          <hr style={{ borderColor: '#3a3e47', marginTop: '20px', marginBottom: '35px' }} />
          <div className='field'>
            <select id="selector-time" className='select-trigger'>
              <option value="00:00:39">Time</option>
              <option value="[0, 0, 0]">GPS</option>
              <option value="Double Touch">Touch</option>
              <option value="Collision">Scene</option>
            </select>
            <select id="selector-action" className='select-trigger' defaultValue="360.000">
              <option value="360.000">Rotation</option>
              <option value="2.000">Scale</option>
            </select>
            <div id="trigger-button-up" className='select-trigger-button-up' onClick={debouncedHandleTriggerButtonUpClick}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div id="trigger-button-down" className='select-trigger-button-down' onClick={debouncedHandleTriggerButtonDownClick}>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          </div>
          <div className='field-container'>
            <div>
            {selectedModel && selectedModel.index != null && getTriggerInfoForModel(selectedModel.index).map((info, index) => (
              <div className='triggers-container' key={index}>
                <div className='triggers-field'>
                  <div className='triggers-div'>
                    <label className='triggers-label' htmlFor="position">{info.timeText}_{index}</label>
                  </div>
                  <div>
                    <input className="rounded-input-trigger" type="text" value={info.time} readOnly />
                  </div>
                </div>
                <div className='triggers-field'>
                  <div className='triggers-div'>
                    <label className='triggers-label' htmlFor="position">{info.actionText}_{index}</label>
                  </div>
                  <div>
                    <input className="rounded-input-trigger" type="text" value={info.action} readOnly />
                  </div>
              </div>
              </div>
            ))}
            </div>
          </div>
          <button type="button" className='delete-button' onClick={handleDeleteSelectedAsset}>Delete</button>
        </>
        ) : (
          <span>Select an asset for more details...</span>
        )}
      </div>
    </div>
  );
});

export default ObjectData;