import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, GizmoHelper, GizmoViewport, Environment } from '@react-three/drei';
import Model from './Model';
import Rectangle from './Rectangle';
import Cylinder from './Cylinder';
import Screens from './Screens';
import DefaultModel from './DefaultModel';
import '../stylesheets/ThreeScene.css';
import { OrthographicCamera } from '@react-three/drei';
import CameraView from './CameraView';
import TruncatedPyramid from './TruncatedPyramid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { EffectComposer, N8AO, TiltShift2 } from "@react-three/postprocessing"

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import VideoComponent from './VideoComponent';
import AudioComponent from './AudioComponent';
import ImageComponent from './ImageComponent';
import { Suspense } from 'react';
import { debounce } from 'lodash';

const ThreeScene = React.memo(({ hiddenModels, height, width, depth, deviceData, modelFiles, setModelFiles, selectedModel, setSelectedModel, 
  targetPosition, cameraPosition, dynamicCameras, setOrbitCameraPosition, setOrbitCameraTarget, cameraClickHandler, resetCamera, updatePositionRotationCamera,
  environmentFile, environmentData, setModelTriggerInfo}) => {
  const topViewPosition = [0, 50, 0];
  const frontViewPosition = [0, 0, 50];
  const sideViewPosition = [50, 0, 0];
  const [isTopView, setIsTopView] = useState(false);
  const [isFrontView, setIsFrontView] = useState(false);
  const [isSideView, setIsSideView] = useState(false);
  
  const orbitControlsRef = useRef(null);
  const hdr1_local=`${process.env.PUBLIC_URL}/old_depot_2k.hdr`;
  const hdr2_local=`${process.env.PUBLIC_URL}/buikslotermeerplein_2k.hdr`;
  const hdr3_local=`${process.env.PUBLIC_URL}/sunset_jhbcentral_2k.hdr`;
  const hdr4_local=`${process.env.PUBLIC_URL}/zwartkops_curve_afternoon_2k.hdr`;

  useEffect(() => {
    return () => {
      debouncedSetSelectedModelNull.cancel();
      debouncedToggleTopView.cancel();
    };
  }, []);

  /*useEffect(() => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.target.set(...targetPosition);
      orbitControlsRef.current.update();
    }
  }, [targetPosition]);*/

  const handleOrbitControlsChange = useCallback(
    debounce(() => {
      if (orbitControlsRef.current) {
        const currentCameraPosition = orbitControlsRef.current.object.position;
        const currentCameraTarget = orbitControlsRef.current.target;

        setOrbitCameraPosition([currentCameraPosition.x, currentCameraPosition.y, currentCameraPosition.z]);
        setOrbitCameraTarget([currentCameraTarget.x, currentCameraTarget.y, currentCameraTarget.z]);
      }
    }, 100),
    []
  );

  const debouncedSetSelectedModelNull = debounce(() => {
    setSelectedModel(null);
  }, 100);

  const debouncedToggleTopView = debounce(() => {
    setIsTopView(prevState => !prevState);
  }, 100);

  const debouncedToggleFrontView = debounce(() => {
    setIsFrontView(prevState => !prevState);
  }, 100);

  const debouncedToggleSideView = debounce(() => {
    setIsSideView(prevState => !prevState);
  }, 100);

  const [altPressed, setAltPressed] = useState(false);
  const [controlPressed, setControlPressed] = useState(false);
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey || event.ctrlKey) {
        event.preventDefault();
      }
      setAltPressed((prev) => event.altKey || prev);
      setControlPressed((prev) => event.ctrlKey || prev);
    };
  
    const handleKeyUp = (event) => {
      setAltPressed((prev) => (event.altKey ? prev : false));
      setControlPressed((prev) => (event.ctrlKey ? prev : false));
    };
  
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);  

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete') {
        event.preventDefault();
        if (selectedModel?.index != null) {
          const modelIndex = selectedModel.index;
  
          setModelFiles((prevModelFiles) =>
            prevModelFiles.filter((_, index) => index !== modelIndex)
          );
          
          setSelectedModel(null);
  
          setModelTriggerInfo((prevTriggerInfo) => {
            const updatedTriggerInfo = { ...prevTriggerInfo };
            delete updatedTriggerInfo[modelIndex];
            return updatedTriggerInfo;
          });
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedModel, setModelFiles, setSelectedModel, setModelTriggerInfo]);   

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F1') {
        event.preventDefault();
        debouncedToggleFrontView();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [debouncedToggleFrontView]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F2') {
        event.preventDefault();
        debouncedToggleSideView();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [debouncedToggleSideView]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F3') {
        event.preventDefault();
        debouncedToggleTopView();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [debouncedToggleTopView]);
  
  return (
    <div className='container-TS'>
      <Canvas className='canvas-TS' onPointerMissed={() => {
        if (!altPressed && !controlPressed) {
          debouncedSetSelectedModelNull();
        }
      }}>
      <axesHelper args={[25]} />
        <color attach="background" args={['#000000']} />
        <ambientLight intensity={2.5} />
        <pointLight position={[10, 10, 10]}/>

        {parseInt(environmentData) === 1 && (
          <Environment 
            files={hdr1_local} 
            ground={{ height: 35, radius: 100, scale: 100 }} 
            background 
            intensity={0.25} 
          />
        )}

        {parseInt(environmentData) === 2 && (
          <Environment 
            files={hdr2_local} 
            ground={{ height: 35, radius: 100, scale: 100 }} 
            background 
            intensity={0.25} 
          />
        )}

        {parseInt(environmentData) === 3 && (
          <Environment 
            files={hdr3_local} 
            ground={{ height: 35, radius: 100, scale: 100 }} 
            background 
            intensity={0.25} 
          />
        )}

        {parseInt(environmentData) === 4 && (
          <Environment 
            files={hdr4_local} 
            ground={{ height: 35, radius: 100, scale: 100 }} 
            background 
            intensity={0.25} 
          />
        )}

        {modelFiles.map((modelFile, index) => (
          modelFile.type === 1 && !hiddenModels.includes(index) ? (
            <ImageComponent 
              key={index}
              position={modelFile.position}
              index={index}
              imageFile={modelFile.urlProcessedFile} 
              modelName={modelFile.name}
              scale={modelFile.scale}
              rotation={modelFile.rotation}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              setModelFiles={setModelFiles}
            />
          ) : null
        ))}

        {modelFiles.map((modelFile, index) => (
          modelFile.type === 2 && !hiddenModels.includes(index) ? (
            <AudioComponent 
              key={index}
              position={modelFile.position}
              index={index}
              audioFile={modelFile.urlProcessedFile} 
              modelName={modelFile.name}
              scale={modelFile.scale}
              rotation={modelFile.rotation}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              setModelFiles={setModelFiles}
            />
          ) : null
        ))}

        {modelFiles.map((modelFile, index) => (
          modelFile.type === 3 && !hiddenModels.includes(index) ? (
            <VideoComponent 
              key={index}
              position={modelFile.position}
              index={index}
              videoFile={modelFile.urlProcessedFile}
              modelName={modelFile.name}
              scale={modelFile.scale}
              rotation={modelFile.rotation}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              setModelFiles={setModelFiles}
            />
          ) : null
        ))}

        {modelFiles.map((modelFile, index) => (
          modelFile.type === 0 && !hiddenModels.includes(index) ? (
            <Suspense key={index}>
              <Model
                key={index}
                position={modelFile.position}
                index={index}
                modelFile={modelFile.urlProcessedFile}
                modelName={modelFile.name}
                scale={modelFile.scale}
                rotation={modelFile.rotation}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                setModelFiles={setModelFiles}
              />
              </Suspense>
          ) : null
        ))}

        <Rectangle 
          height={height} 
          width={width} 
          depth={depth} 
          position={[0, depth/2, 0]} 
          rotationX={Math.PI/2} 
          rotationY={Math.PI/2} 
          rotationZ={Math.PI/2} 
        />
        
        {parseInt(deviceData) === 5 && (
          <>
            <DefaultModel
              key="desk"
              gltf={`${process.env.PUBLIC_URL}/desk.glb`}
              position={[0, 0.5, -8]}
              scale={1.87}
            />
            <DefaultModel
              key="presenter"
              gltf={`${process.env.PUBLIC_URL}/presenter.glb`}
              position={[0, 0.5, -9.5]}
              scale={1.75}
            />
            <Screens height={3} width={5} position={[-3, 3, -10]} rotationY={Math.PI/-4}/>
            <Screens height={3} width={5} position={[3, 3, -10]} rotationY={Math.PI/4} />
            <Cylinder height={0.5} radius={3} position={[0, 0.25, -8]} />
            <Cylinder height={0.5} radius={2.5} position={[0, 0.25, 8]} />
          </>
        )}
        <gridHelper args={[100, 100]} />

        {dynamicCameras.map((camera) => (
           <group key={camera.id}>
            <CameraView
              key={camera.id}
              id={camera.id}
              position={camera.position}
              rotation={camera.rotation}
              updatePositionRotationCamera={updatePositionRotationCamera}
              target={camera.lookAt}
            />
            <TruncatedPyramid
              bottomRadius={2}
              topRadius={0.2}
              height={3}
              position={camera.position}
              rotation={camera.rotation}
            />
        </group>
        ))}
        {resetCamera ? null : (
          isTopView ? (
            <OrthographicCamera
              makeDefault
              position={topViewPosition}
              zoom={15}
              near={0.1}
              far={1000}
            />
          ) : isFrontView ? (
            <OrthographicCamera
              makeDefault
              position={frontViewPosition}
              zoom={15}
              near={0.1}
              far={1000}
            />
          ) : isSideView ? (
            <OrthographicCamera
              makeDefault
              position={sideViewPosition}
              zoom={15}
              near={0.1}
              far={1000}
            />
          ) : (
            <PerspectiveCamera position={cameraPosition} makeDefault />
          )
        )}
        <EffectComposer disableNormalPass multisampling={8}>
          <N8AO aoRadius={50} distanceFalloff={0.2} intensity={6} screenSpaceRadius halfRes />
        </EffectComposer>
        <OrbitControls 
          ref={orbitControlsRef} 
          target={targetPosition} 
          enableRotate={!isFrontView && !isSideView && !isTopView && altPressed} 
          enablePan={controlPressed}
          //onChange={handleOrbitControlsChange}
        />
        <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
          <GizmoViewport labelColor="white" axisHeadScale={1} />
        </GizmoHelper>
        </Canvas>
      <button className='button-TS' onClick={debouncedToggleTopView}>
        {isTopView ? <FontAwesomeIcon icon={faCube} className='icon-TS' /> : <FontAwesomeIcon icon={faBinoculars} className='icon-TS' />}
      </button>
    </div>
  );
});

export default ThreeScene;
