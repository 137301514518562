import React, { useState, useEffect } from 'react';
import '../stylesheets/ImageLoader.css';

const ImageLoader = ({ fileImageRef, handleImageUpload, loading, toggleImageLoader }) => {
  
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [isUrlValid, setIsUrlValid] = useState(false);

  const uploadImageFromFileButton = () => {
    fileImageRef.current.click();
  };

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setIsUrlValid(validateUrl(url));
  }, [url]);

  return (
    <div className='object-Imageloader-container'>
      <h3>Image Loader</h3>
      <hr style={{ borderColor: '#3a3e47', marginTop: '20px'}} />
      <div className='field-Imageloader-container'>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className='Imageloader-button' 
            onClick={uploadImageFromFileButton}
            >Upload Image from your device</button>
          </div>
          <div className='field'>
            <input
              id="ImageInput"
              ref={fileImageRef}
              type="file"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              disabled={loading}
              accept=".jpg,.jpeg,.png,.webp,image/jpeg,image/png,image/webp"
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input 
            className='urlLoader-input' 
            type="text" 
            name="urlImageloader" 
            placeholder="Enter Image URL" 
            value={url} 
            onChange={(e) => setUrl(e.target.value)}
            disabled={loading} 
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button 
            type="button" 
            className={`Imageloader-button ${!isUrlValid || loading ? 'disabled' : ''}`}
            onClick={() => handleImageUpload(null, url)}
            disabled={!isUrlValid || loading} 
            >Import Image from URL</button>
          </div>
          <button type="button" className='close-button' onClick={toggleImageLoader}>Close</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ImageLoader;
