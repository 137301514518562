// src/components/ProtectedRoute.js
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  
  const users = [
    { username: 'admin', password: 'xrcapsuleproject' },
    { username: 'ivan', password: 'pwdivan' },
    { username: 'testuser', password: 'torinoxreco' }
  ];

  const location = useLocation();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const isAuthenticated = () => {
    const userData = getCookie('userData');
    if (userData) {
      try {
        const { username, password } = JSON.parse(userData);
        return users.some(user => user.username === username && user.password === password);
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const basketId = queryParams.get('basket_id');
    const accessToken = queryParams.get('access_token');
    const refreshToken = queryParams.get('refresh_token');
    
    if (basketId && accessToken) {
      localStorage.setItem('basket_id', basketId);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
    }
  }, [location]);

  if (isAuthenticated() && location.pathname === '/login') {
    return <Navigate to="/project" />;
  }

  if (!isAuthenticated() && location.pathname === '/login') {
    return element;
  }

  return isAuthenticated() ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
