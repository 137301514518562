import React, { useEffect} from 'react';
import '../stylesheets/NavBar.css';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function NavBar({userName, setUserName}) {

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const navigate = useNavigate();

    const handleLogOut = () => {
        localStorage.clear();
        deleteCookie('userData');
        navigate('/login');
    };

    useEffect(() => {
        const userData = getCookie('userData');
        if (userData) {
            try {
                const { username } = JSON.parse(userData);
                setUserName(username || '');
            } catch (e) {
                setUserName('');
            }
        }
    }, []);

    return (
        <header className="App-header">
            <nav className="navbar">
                <span className="left-text"><FontAwesomeIcon icon={faUser}/> {userName}</span>
                <span className="right-text" onClick={handleLogOut}><FontAwesomeIcon icon={faRightFromBracket}/> LOGOUT</span>
            </nav>
        </header>
    );
}
export default NavBar;